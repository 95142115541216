import { refreshUserStore, scopesSatisfied } from '@/Authentication'
import { createRouter, createWebHashHistory } from 'vue-router'
import pinia from '@/store'
import { useUserStore } from '../store/UserStore'
import { useFormHelperStore } from '@/store/FormHelperStore';
import { strBool } from '@/utils/StrBool'
import { useTitle } from '@vueuse/core'
import { pageRequiredScopes } from '@/utils/PagePermissions';
import { routes } from './RoutesDef';

// Create the vue router instance
// Note the following peculiarities regarding the history setting:
// - createWebHistory uses clean URLs (e.g., https://url/path) but requires that a dedicated server instance serves the frontend
// - createWebHashHistory adds a hash to URLs (e.g., https://url/#/path) and allows the frontend to be served as a static page, which often makes it cheaper to host
const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

/**
 * Below, some of the "before guards" and "after hooks" are called.
 * Please consult the following list for the full navigation resolution flow:
 * https://router.vuejs.org/guide/advanced/navigation-guards.html#the-full-navigation-resolution-flow
 */

/**
 * The before guard is called whenever a navigation is triggered.
 * Here, it is being used to check the user's login state. They are redirected to 
 * the sign in page if they're not logged in.
 */
router.beforeEach(async (to, from, next) => {
  if (strBool(process.env.VUE_APP_SKIP_LOGIN_CHECK)) {
    console.log(`Skip login check`)
    next();
    return;
  }
  // make sure the user is authenticated
  const userStore = useUserStore(pinia);
  await refreshUserStore();
  const isLoggedIn = userStore.$state.isAuthorized;
  if (!isLoggedIn && to.name !== 'login') {
    next({name: 'login'});
  } else if (isLoggedIn && to.name === 'login') {
    next({name: 'home'});
  }

  // make sure the user has sufficient access scope
  if (
    Object.hasOwn(pageRequiredScopes, to.path)
    && !scopesSatisfied(pageRequiredScopes[to.path])
  ) {
    console.log('Access scopes unsatisfied, redirecting');
    next({name: 'error-perm'});
    return;
  }

  // user has passed all clearance checks
  next();
})

/**
 * The after hook is called after navigation is confirmed.
 * Here, it is used to set the page title.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.afterEach(async (to, from) => {
  if (typeof (to as any).meta.title === 'string') {
    useTitle((to as any).meta.title)
  }

  const userStore = useUserStore(pinia);
  const isLoggedIn = userStore.$state.isAuthorized;
  userStore.$state.showDevTools = scopesSatisfied({global: ['su']});
  if (isLoggedIn) {
    const formHelperStore = useFormHelperStore(pinia);
    await formHelperStore.refreshAll({forced: false});
  }
})

export default router
