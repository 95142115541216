import { defineStore } from 'pinia';
import axios from 'axios';
import config from '@/config';

/**
 * This store is for user login state and preferences
 */
export const useFormHelperStore = defineStore({
  id: 'formHelperStore',
  state: () => ({
    userDirectory: {} as {[userId: number]: string}
  }),
  actions: {
    /**
     * Refresh all fields
     * @param forced: if set to true, refreshes everything; otherwise only refresh variables that are empty
     */
    async refreshAll(
      {
        forced = false
      } = {}
    ) {
      const proms: Array<Promise<any>> = [];
      if (forced || Object.keys(this.userDirectory).length === 0) {
        proms.push(this.refreshUserDirectory());
      }
      await Promise.all(proms);
    },
    /**
     * Refresh the user directory with the current backend user list.
     */
    async refreshUserDirectory() {
      this.userDirectory = {};
      const userReq = await axios.get(`${config.http.backEndUrl}/user/all`);
      if (userReq.data?.successful) {
        const userArr = userReq.data?.result
        for (const user of userArr) {
          this.userDirectory[user.user_id] = user.name;
        }
      }
    },
  }
})