import { RequiredScopes, ScopeSlug } from "@/Authentication"

const allNormalLevels: ScopeSlug[] = ['owner', 'manage', 'create', 'read']

export const pageRequiredScopes: {[page: string]: RequiredScopes} = {
  // Ordering and Fulfillment
  '/ordering-and-fulfillment': {
    'part_ordering': allNormalLevels,
    'part_fulfillment': allNormalLevels,
    'ph_ordering': allNormalLevels,
    'ph_fulfillment': allNormalLevels,
  },
  // Ordering and Fulfillment: Ordering & Fulfillment
  '/ordering-and-fulfillment/part-ordering': {
    'part_ordering': ['owner', 'manage', 'create']
  },
  '/ordering-and-fulfillment/part-fulfillment': {
    'part_fulfillment': ['owner', 'manage', 'create']
  },
  '/ordering-and-fulfillment/printhead-ordering': {
    'ph_ordering': ['owner', 'manage', 'create']
  },
  '/ordering-and-fulfillment/printhead-fulfillment': {
    'ph_fulfillment': ['owner', 'manage', 'create']
  },
  '/ordering-and-fulfillment/part-required-inventory': {
    'part_ordering': ['owner', 'manage']
  },
  '/ordering-and-fulfillment/printhead-required-inventory': {
    'ph_ordering': ['owner', 'manage']
  },
  // Ordering and Fulfillment: Orders
  '/ordering-and-fulfillment/part-order-overview-data': {
    'part_ordering': allNormalLevels,
    'part_fulfillment': allNormalLevels,
  },
  '/ordering-and-fulfillment/part-required-inventory-overview-data': {
    'part_ordering': ['owner', 'manage']
  },
  '/ordering-and-fulfillment/printhead-order-overview-data': {
    'ph_ordering': allNormalLevels,
    'ph_fulfillment': allNormalLevels,
  },
  '/ordering-and-fulfillment/printhead-required-inventory-overview-data': {
    'ph_ordering': ['owner', 'manage']
  },
  // Ordering and Fulfillment: Inventory Data
  '/ordering-and-fulfillment/printhead-inventory-overview-data': {
    'ph_ordering': allNormalLevels
  },

  // Manufacturing Management
  '/manufacturing-management': {
    'manufacturing_management': allNormalLevels
  },
  '/manufacturing-management/new-printhead-form': {
    'manufacturing_management': ['create']
  },
  '/manufacturing-management/printhead-manage-active-state-form': {
    'manufacturing_management': ['create']
  },
  '/manufacturing-management/new-part-manufacturing-version': {
    'manufacturing_management': ['create']
  },
  '/manufacturing-management/part-manage-active-state-form': {
    'manufacturing_management': ['create']
  },
  '/manufacturing-management/printhead-action-procedure-form': {
    'manufacturing_management': ['create']
  },
  '/manufacturing-management/mould-action-procedure-form': {
    'manufacturing_management': ['create']
  },
  '/manufacturing-management/part-action-procedure-form': {
    'manufacturing_management': ['create']
  },
  '/manufacturing-management/mould-catalogue': {
    'manufacturing_management': ['read']
  },
  '/manufacturing-management/part-catalogue': {
    'manufacturing_management': ['read']
  },
  '/manufacturing-management/printhead-catalogue': {
    'manufacturing_management': ['read']
  },

  // Manufacturing
  '/manufacturing': {
    'manufacturing': allNormalLevels
  },
  // Manufacturing: Mould
  '/manufacturing/mould-production-form': {
    'manufacturing': ['create']
  },
  '/manufacturing/mould-preparation-form': {
    'manufacturing': ['create']
  },
  '/manufacturing/mould-inspection-report-form': {
    'manufacturing': ['create']
  },
  // Manufacturing: 'Part
  '/manufacturing/moulding-form': {
    'manufacturing': ['create']
  },
  '/manufacturing/demoulding-form': {
    'manufacturing': ['create']
  },
  '/manufacturing/injection-moulded-part-form': {
    'manufacturing': ['create']
  },
  '/manufacturing/thin-film-bonding-form': {
    'manufacturing': ['create']
  },
  '/manufacturing/part-adjustment-intent-form': {
    'manufacturing': ['create']
  },
  '/manufacturing/part-preparation-form': {
    'manufacturing': ['create']
  },
  '/manufacturing/part-inspection-report-form': {
    'manufacturing': ['create']
  },
  // Manufacturing: Printhead
  '/manufacturing/printhead-set-form': {
    'manufacturing': ['create']
  },
  '/manufacturing/printhead-inspection-report': {
    'manufacturing': ['create']
  },
  '/manufacturing/printhead-return-form': {
    'manufacturing': ['create']
  },
  // Manufacturing: Data
  '/manufacturing/mould-status-data-table': {
    'manufacturing': ['read']
  },
  '/manufacturing/part-status-data-table': {
    'manufacturing': ['read']
  },
  '/manufacturing/printhead-status-data-table': {
    'manufacturing': ['read']
  },
  '/manufacturing/part-manufacturing-demand-overview-data': {
    'manufacturing': ['read']
  },
  '/manufacturing/printhead-manufacturing-demand-overview-data': {
    'manufacturing': ['read']
  },
  // Manufacturing: Part Priority Overview
  '/manufacturing/part-priority-overview': {
    'manufacturing': ['read']
  },
  '/manufacturing/printhead-priority-overview': {
    'manufacturing': ['read']
  },
  '/manufacturing/mould-kpi-data': {
    'manufacturing': ['read']
  },
  '/manufacturing/part-kpi-data': {
    'manufacturing': ['read']
  },
  '/manufacturing/printhead-kpi-data': {
    'manufacturing': ['read']
  },
  '/manufacturing/mould-inventory-priorities': {
    'manufacturing': ['read']
  },
  '/manufacturing/part-moulding-priorities': {
    'manufacturing': ['read']
  },
  '/manufacturing/part-injection-moulding-priorities': {
    'manufacturing': ['read']
  },
  '/manufacturing/part-thin-film-priorities': {
    'manufacturing': ['read']
  },
  '/manufacturing/part-adjustment-priorities': {
    'manufacturing': ['read']
  },
  '/manufacturing/part-preparation-priorities': {
    'manufacturing': ['read']
  },
  '/manufacturing/part-inspection-priorities': {
    'manufacturing': ['read']
  },
  '/manufacturing/printhead-set-priorities': {
    'manufacturing': ['read']
  },
  '/manufacturing/printhead-inspection-priorities': {
    'manufacturing': ['read']
  },

  // Manufacturing Investigation
  '/manufacturing-investigation': {
    'manufacturing_investigation': ['owner', 'manage', 'create']
  },
  '/manufacturing-investigation/mould-investigation-report-form': {
    'manufacturing_investigation': ['create']
  },
  '/manufacturing-investigation/part-investigation-report-form': {
    'manufacturing_investigation': ['create']
  },
  '/manufacturing-investigation/printhead-investigation-report': {
    'manufacturing_investigation': ['create']
  },

  // Administration
  '/administration': {
    '*': ['owner']
  }
}