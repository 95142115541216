<template>
  <v-app>
    <!-- app layout container -->
    <v-layout>
      <!-- top bar -->
      <v-app-bar app density="compact">
        <template v-slot:prepend>
          <!-- toggle nav drawer visibility -->
          <v-app-bar-nav-icon @click.stop="drawerVisible = !drawerVisible"></v-app-bar-nav-icon>
        </template>
        <!-- website title -->
        <v-app-bar-title class="headline text-uppercase">
          <span>Aspect Biosystems </span>
          <span class="font-weight-light">Data Portal {{ config.appBarTitle.titleAppendStr }}</span>
        </v-app-bar-title>
        <div class="text-center mr-4">
          <!-- dark mode toggle -->
          <v-menu open-on-hover :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" icon="mdi-cog"></v-btn>
            </template>
            <v-list>
              <!-- dark theme toggle -->
              <v-list-item min-width="250px">
                <v-list-item-title>
                  <v-icon>mdi-weather-night</v-icon>&nbsp;Dark theme
                </v-list-item-title>
                <template v-slot:append>
                  <v-list-item-action start>
                    <v-switch
                      v-model="useDarkTheme"
                      hide-details
                    ></v-switch>
                  </v-list-item-action>
                </template>
              </v-list-item>
              <!-- dense tables toggle -->
              <v-list-item>
                <v-list-item-title>
                  <v-icon>mdi-view-compact</v-icon>&nbsp;Dense tables
                </v-list-item-title>
                <template v-slot:append>
                  <v-list-item-action start>
                    <v-switch
                      v-model="useDenseTables"
                      hide-details
                    ></v-switch>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- user account menu -->
          <v-menu open-on-hover v-if="isAuthorized">
            <template v-slot:activator="{ props }">
              <!-- show the user's name if signed in -->
              <v-btn color="primary" v-bind="props">
                <v-icon>mdi-account</v-icon>&nbsp;{{ displayName }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <!-- sign out icon -->
                <v-btn :href="config.http.backEndUrl + '/login/logout'">
                  <v-icon>mdi-logout</v-icon>&nbsp;Sign Out
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-app-bar>
      <!-- side navigation drawer -->
      <v-navigation-drawer
        app
        expand-on-hover
        rail
        v-model="drawerVisible"
      >
        <!-- show navigable items defined in variable navItems -->
        <v-list density="compact" nav>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :title="item.title"
            :to="item.path"
            :prepend-icon="item.icon"
          >
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <!-- main app view (contents filled in by router corresponding to current page) -->
      <v-main>
        <!-- router fills in view corresponding to current page -->
        <router-view/>
      </v-main>
    </v-layout>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import pinia from './store'
import { storeToRefs } from 'pinia'
import { useUserStore } from './store/UserStore'
import config from './config'
import { RequiredScopes, scopesSatisfied } from '@/Authentication';
import { useTheme } from 'vuetify';
import { pageRequiredScopes } from './utils/PagePermissions'

const navItems: Array<{title: string, icon: string, path: string, requiredScopes?: RequiredScopes}> = [
  {
    title: 'Home',
    icon: 'mdi-home',
    path: '/'
  },
  {
    title: 'Ordering & Fulfillment',
    icon: 'mdi-store',
    path: '/ordering-and-fulfillment',
    requiredScopes: pageRequiredScopes['/ordering-and-fulfillment']
  },
  {
    title: 'Manufacturing',
    icon: 'mdi-crane',
    path: '/manufacturing',
    requiredScopes: pageRequiredScopes['/manufacturing']
  },
  {
    title: 'Manufacturing Management',
    icon: 'mdi-hard-hat',
    path: '/manufacturing-management',
    requiredScopes: pageRequiredScopes['/manufacturing-management']
  },
  {
    title: 'Investigation',
    icon: 'mdi-magnify',
    path: '/manufacturing-investigation',
    requiredScopes: pageRequiredScopes['/manufacturing-investigation']
  },
  {
    title: 'Admin',
    icon: 'mdi-wizard-hat',
    path: '/administration',
    requiredScopes: {
      '*': ['owner']
    }
  }
]

export default defineComponent({
  name: 'App',
  setup: () => {
    const userStore = useUserStore(pinia);
    const theme = useTheme();
    // read states from store
    const { isAuthorized, displayName, scopes, useDarkTheme, useDenseTables } = storeToRefs(userStore)
    // make vars available to component
    return {
      isAuthorized,
      displayName,
      scopes,
      useDarkTheme,
      useDenseTables,
      theme
    }
  },
  data: () => ({
    drawerVisible: null as null | boolean,  // set to null to let Vue choose default depending on mobile vs desktop
    config,
  }),
  watch: {
    useDarkTheme: function (newDarkThemeSetting) {
      this.theme.global.name.value = newDarkThemeSetting ? 'dark' : 'light';
    }
  },
  computed: {
    /**
     * Return navItems that the user has sufficient scope to access.
     */
    items: () => {
      return navItems.filter((elem) => {
        return elem.requiredScopes ? scopesSatisfied(elem.requiredScopes) : true;
      });
    }
  },
  methods: {
  },
  mounted() {
    // apply the user's theme preference upon mount
    this.theme.global.name.value = this.useDarkTheme ? 'dark' : 'light'
  }
})
</script>